import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/radu/code/ebe/src/components/BookLayout.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Ai auzit că Dumnezeu este bun, drept, sfânt, omniscient, atotputernic, omniprezent, credincios și neschimbător. Acestea sunt câteva din atributele biblice prin care Dumnezeu este prezentat în acest curs.
Cursul este eficient în grupuri de femei și tineri. Liderii de tineret și învățătorii de școală duminicală vor învăța foarte multe lucruri frumoase despre Dumnezeu care să le schimbe viața și să le fie de folos în lucru cu copiii și tinerii. Predicatorii au numai de câștigat.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Modul în care se parcurge materialul, face foarte necesar cititul din Biblie și munca individuală pe pasaje din Scriptură, aspect care aduce unul dintre marile foloase ale cursului.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Să nu uităm, persoana lui Dumnezeu este în centru cât timp vom lucra la acest curs!`}</MDXTag>
      <MDXTag name="p" components={components}>{`Citat: „Un fapt uimitor în ce privește omnisciența (atotcunoașterea) lui Dumnezeu este nu numai că El cunoaște toate detaliile din trecut, prezent și viitor dar cunoaște și toate posibilitățile; El știe `}{`[ce ar fi dacă…?]`}{`. Te-ai întrebat vreodată: Cum ar fi dacă toate ar fi fost altfel? Dumnezeu știe exact cum ar fi fost! Cât de clar este că trebuie să căutăm sfatul și călăuzirea Dumnezeului nostru, singurul care vede întreaga imagine.”`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    